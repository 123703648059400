import React from "react";
import "./swma.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import sem111 from "../../assets/images/x5.jpeg";
import kids from "../../assets/images/kidii.jpg";
import choirImage1 from "./../../assets/images/church/IMG_8917.jpeg";
import choirImage2 from "./../../assets/images/church/IMG_8923.jpeg";
import choirImage3 from "./../../assets/images/church/IMG_8915.jpeg";
import choirImage4 from "./../../assets/images/church/IMG_8925.jpeg";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { AiFillYoutube } from "react-icons/ai";
import { events, imNewContent } from "../../constants/data";
// import Kids_campus from '../../components/kids_campus/Kids_campus';

const Swma = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });

  return (
    <div id="super">
      <div id="min-group-wrapper">
        <div className="min-group">
          {/* showcase */}
          <div className="swma-group-showcase">
            <div className="min-group-case">
              <div className="min-group-txt">
                <h1 className="focus-in-contract-bck">SWMA</h1>
                {/* <h2 className="focus-in-contract-bck">BIRTH - FIFTH GRADE</h2> */}
              </div>
            </div>
          </div>
          {/* showcase end */}

          {/* <section>
            <div className="kidsWrapper">
              <div
                className="col"
                data-aos="fade-right"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="600"
              >
                <div className="txtContent">
                  <h1>
                    Lorem. <br />
                    dolor sit.
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Accusamus, atque. Nostrum, similique, architecto assumenda
                    eos, dolorem nesciunt praesentium consectetur voluptatem
                    odit odio provident totam eius? Eaque sapiente est pariatur
                    architecto!
                  </p>
                  <a className="btn" href="/">
                    NEW HERE?
                  </a>
                </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="600"
              >
                <div className="imgHolder">
                  <div className="imgOverlay" />
                  <img src={choirImage2} alt="kids image" />
                </div>
                <div className="imgTitle">{/* <h2>some heading</h2> </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
              >
                <div className="imgHolder">
                  <div className="imgOverlay" />
                  <img src={choirImage3} alt="kids image" />
                </div>
                <div className="imgTitle">{/* <h2>some heading</h2> </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="200"
              >
                <div className="imgHolder">
                  <div className="imgOverlay" />
                  <img src={choirImage4} alt="kids image" />
                </div>
                <div className="imgTitle">{/* <h2>some heading</h2> </div>
              </div>
            </div>
          </section> */}

          <section>
            {/* <div className="tt-1 focus-in-contract-bck">SWM</div> */}
            <p className="ttp">
              <h3>
                The Susanna Wesley Fellowship aims to involve all women who are
                full members of the Ghana United Methodist Church. Its
                objectives are as follows:
              </h3>
            </p>
            <p className="ttp" style={{ textAlign: "left" }}>
              <ul>
                <li>
                  {" "}
                  Instill in members a deep sense of holiness and encourage
                  godly living, both within their homes, the Church, and
                  society.
                </li>
                <li>
                  Teach members the importance of nurturing children in the
                  Christian faith.
                </li>
                <li>
                  {" "}
                  Assist in maintaining the cleanliness of the Church premises
                  and contribute to its decoration, as well as the manses.
                </li>
                <li>
                  Provide assistance in serving refreshments and food during
                  various Church meetings.
                </li>
                <li>Advocate for the welfare of the Church's ministers.</li>
                <li>Support the training of ministers within the Church.</li>
              </ul>
            </p>{" "}
            <p className="ttp" style={{ textAlign: "left" }}>
              The primary purpose of the Susanna Wesley Fellowship is to unite
              all women who hold full membership in the Ghana United Methodist
              Church. Its goals encompass the following: <br /> <br />
              <ul>
                <li>
                  Cultivate among members a spirit of holiness and righteous
                  living, fostering these values in their homes, the Church, and
                  the wider community.
                </li>
                <li>
                  Educate and encourage members to prioritize the nurturing of
                  children in the Christian faith.
                </li>
                <li>
                  Contribute to the cleanliness and aesthetic appeal of the
                  Church premises and manses.
                </li>
                <li>
                  Assist in providing refreshments and meals during various
                  Church gatherings.
                </li>
                <li>Advocate for the well-being of the Church's ministers.</li>
                <li>
                  Support and actively participate in the training of ministers
                  within the Church.
                </li>
              </ul>
            </p>
          </section>

          <section className="upcomingEvents">
            <div className="container">
              <h1>upcoming events</h1>
              <div className="eventsList">
                {events.map(({ title }, index) => {
                  return (
                    <a href="/events" className="eventItem" key={index}>
                      {title}
                    </a>
                  );
                })}
              </div>
              <Link to="/events" className="links linkAnima">
                FULL CALENDAR <span className="arrow"></span>
              </Link>
            </div>
          </section>

          <section className="whoWeAre">
            <div className="container">
              <div className="col">
                <div className="contentWrapper">
                  <div className="imgHolder">
                    <img src={choirImage1} alt="" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="contentWrapper">
                  <h1>
                    this is who <br /> we are
                  </h1>
                  <p>{imNewContent.about}</p>
                  <a href="/serve" className="btn">
                    SERVE
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="connect">
            <div className="container">
              <div className="col">
                <div className="contentWrapper">
                  <h1>you don't want to miss this</h1>
                  <a className="btn" href="/">
                    STAY IN THE KNOW
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="contentWrapper">
                  <div className="top">
                    <h4>you can connect with us through the following</h4>
                  </div>
                  <div className="bottom">
                    <div className="social">
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <AiFillTwitterCircle />
                      </a>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <FiInstagram />
                      </a>
                      <a
                        href="https://www.youtube.com/@ghanacalvarymethodistunite9662"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillYoutube />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Swma;
