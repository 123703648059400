import React from "react";
import "./Prayer.css";
import { MdAllInbox } from "react-icons/md";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { imNewContent } from "../../constants/data";

const Prayer = () => {
  return (
    <div id="prayer-wrapper">
      <div className="prayer-case">
        <div className="prayer-show">
          <div className="pray-left">
            <div className="pl-txt focus-in-contract-bck">PRAYER</div>
          </div>

          <div className="pray-right">
            <div className="pr">
              <div className="pr-1">
                <div className="pr1-tt1 focus-in-contract-bck">
                  {imNewContent.prayerContent1}
                </div>
                <div className="pr1-tt2">{imNewContent.prayerContent2}</div>
              </div>

              <div className="pr-2">
                <form
                  action="https://formsubmit.co/info@hohcentre.co.uk"
                  method="POST"
                  className="pf"
                >
                  <p className="pf-1">PRAYER REQUEST FORM</p>

                  <div className="nl">
                    <div className="nl1">
                      <div className="ft-1">FULL NAME</div>
                      <div className="ft-2"></div>
                    </div>
                    <div className="nl2">
                      <input required name="Full_name"></input>
                    </div>
                  </div>

                  <div className="nl">
                    <div className="nl1">
                      <div className="ft-1">EMAIL</div>
                      <div className="ft-2"></div>
                    </div>
                    <div className="nl2">
                      {/* <div className="sinn">
                        <MdAllInbox />
                      </div> */}
                      <input required name="Email" className="zinn"></input>
                    </div>
                  </div>

                  <div className="nl mess-ara">
                    <div className="nl1">
                      <div className="ft-1">REQUEST</div>
                      <div className="ft-2"></div>
                    </div>
                    <div className="nl2">
                      <textarea
                        required
                        name="Prayer_request"
                        placeholder="Please pray that...(up to 500 characters)"
                      ></textarea>
                    </div>
                  </div>

                  <div className="pr-3">
                    <button className="btn">SEND REQUEST</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Prayer;
