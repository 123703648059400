export const events = [
  {
    id: 1,
    dayText: "sunday",
    month: "may",
    day: "15",
    year: "2024",
    title: "Apostolic convocation",
    location: "Lorem ipsum dolor sit amet.",
    startTime: "6:00 pm",
    endTime: "8:30 pm",
  },
  {
    id: 2,
    dayText: "monday",
    month: "may",
    day: "15",
    year: "2024",
    title: "becoming like christ",
    location: "Lorem ipsum dolor sit amet.",
    startTime: "6:00 pm",
    endTime: "8:30 pm",
  },
  {
    id: 3,
    dayText: "sunday",
    month: "september",
    day: "15",
    year: "2024",
    title: "kofi ne ama",
    location: "Lorem ipsum dolor sit amet.",
    startTime: "6:00 pm",
    endTime: "8:30 pm",
  },
  {
    id: 4,
    dayText: "thursday",
    month: "june",
    day: "15",
    year: "2024",
    title: "destiny warnight",
    location: "Lorem ipsum dolor sit amet.",
    startTime: "6:00 pm",
    endTime: "8:30 pm",
  },
  {
    id: 5,
    dayText: "friday",
    month: "april",
    day: "15",
    year: "2024",
    title: "easter convention",
    location: "Lorem ipsum dolor sit amet.",
    startTime: "6:00 pm",
    endTime: "9:30 pm",
  },
  {
    id: 5,
    dayText: "saturday",
    month: "april",
    day: "28",
    year: "2023",
    title: "music festival",
    location: "Lorem ipsum dolor sit amet.",
    startTime: "6:00 pm",
    endTime: "9:30 pm",
  },
];

export const homeContent = {
  heroText1: "WELCOME TO",
  heroText2: "GHANA CALVARY METHODIST UNITED CHURCH",
  missionText:
    "The Ghana Calvary Methodist United Church exists in response to God’s invitation, with those who share a commitment to Jesus Christ as Savior and Lord, to be a community to know Christ better and make him better known to people everywhere, and to equip for ministry to all who respond in faith.",
  visionText:
    " Ghana Calvary Methodist Church shall be a vibrant, spirit-filled, prayerful, community oriented and bible practicing people who are committed to witnessing to others about the love and saving grace of our Lord Jesus Christ. We seek to minister to the spiritual, physical, and social needs of the people in the church and the community through worship, fellowship, and outreach.",
  ourBelieve:
    "As a Methodist institution, we firmly ground our values in the Bible. We firmly believe in the inspiration of the Bible, the existence of a triune God, and the deity of Jesus Christ. The Nicene Creed and the Apostle's Creed serve as a succinct explanation of the beliefs held by all Methodists:",
  apostleCreed:
    "I believe in God the Father, Almighty, Maker of heaven and earth and in Jesus Christ, his only begotten Son, our Lord, who was conceived by the Holy Ghost, born of the Virgin Mary, suffered under Pontius Pilate; was crucified, dead and buried: He descended into hell:The third day he rose again from the dead:He ascended into heaven, and sits at the right hand of God the Father Almighty: From thence he shall come to judge the quick and the dead I believe in the Holy Ghost: I believe in the holy Catholic Church: the communion of saints: The forgiveness of sins, The resurrection of the body, And the life everlasting. Amen.",
  niceneCreed:
    "I believe in one God, the Father Almighty, Maker of heaven and earth, and of all things visible and in visible. \n\n And in one Lord Jesus Christ, the only-begotten Son of God, begotten of the Father before all worlds; God of God, Light of Light, very God of very God; begotten, not made, being of one substance with the Father, by whom all things were made. \n\n Who, for us men for our salvation, came down from heaven, and was incarnate by the Holy Spirit of the virgin Mary, and was made man; and was crucified also for us under Pontius Pilate; He suffered and was buried; and the third day He rose again, according to the Scriptures; and ascended into heaven, and sits on the right \n\n hand of the Father; and He shall come again, with glory, to judge the quick and the dead; whose kingdom shall have no end. \n\n  And I believe in the Holy Ghost, the Lord and Giver of Life; who proceeds from the Father [and the Son]; who with the Father and the Son together is worshipped and glorified; who spoke by the prophets.\n\n And I believe one holy catholic and apostolic Church. I acknowledge one baptism for the remission of sins; and I look for the resurrection of the dead, and the life of the world to come. Amen. ",
};

export const imNewContent = {
  about:
    "The Ghana Calvary Methodist United Church, as its name implies, is a Methodist Church situated in Toronto and affiliated with the United Church of Canada. It was established in 1999 by Rev. Charles Koffie, along with a community of immigrants from the West African country of Ghana. With a congregation of around 300 members, the church primarily consists of Ghanaian immigrants in Canada. Worship services at the church are conducted in a blend of English and Akan, and everyone is warmly welcomed to participate.",
  whatToExpect:
    "Remember, you are not alone. In this community of faith, we are committed to supporting one another through prayer, love, and compassion. Together, let us find comfort, solace, and renewed hope through the power of God.",
  prayerContent1:
    "We are aware that the path through life may include hardships, triumphs, and times when we require more help. ",
  prayerContent2:
    "We firmly believe in the efficacy of prayer and the resilience of a loving neighbourhood. We urge you to submit your prayer request with us. Our dedicated prayer team is ready to lift you up in prayer and provide spiritual support during this time.To submit your prayer request, please fill out the form below. Your request will be treated with utmost confidentiality and care.",
};

export const upComingEvents = [
  {
    id: "1",
    title: "Apostolic convocation",
    details: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    url: "/",
  },
  {
    id: "2",
    title: "Becoming Like Christ",
    details: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    url: "/",
  },
  {
    id: "3",
    title: "Kofi ne Ama",
    details: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    url: "/",
  },
  {
    id: "4",
    title: "Easter convention",
    details: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    url: "/Easter_service",
  },
  {
    id: "5",
    title: "Destiny Warnight",
    details: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    url: "/",
  },
];
