import React from "react";
import "./Location.css";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { upComingEvents } from "../../constants/data";

const Location = () => {
  return (
    <div className="location-page">
      <div className="loc-wrapper">
        <div className="loc">
          <div className="loc-1">UPCOMING EVENTS</div>

          <div className="loc-2">
            <Link to="/contact" className="links linkAnima">
              Contact Us <span className="arrow"></span>
            </Link>
          </div>
        </div>

        {/* campuses */}

        <div className="campus-wrapper">
          <div className="campus-1">
            {/* campus flex */}
            {upComingEvents.map((event, index) => (
              <div className="campus" key={index}>
                <div className="city">{event.title}</div>

                <div className="broad"></div>

                <div className="add hover_overlay_2">{event.details}</div>

                {/* <div className="ser">SERVICES</div> */}

                {/* <div className="day">Sunday: 9 & 11 AM</div> */}

                <div className="info">
                  <Link to={event.url} className="links linkAnima">
                    MORE INFO <span className="arrow"></span>
                  </Link>
                </div>
              </div>
            ))}
            {/* campus flex end */}
          </div>
        </div>

        {/* campuses */}
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Location;
