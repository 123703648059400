import { Link } from "react-router-dom";
import "./home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Slides from "../../components/msgSlider/slider";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { AiFillYoutube } from "react-icons/ai";
// import kmmm3 from "../../assets/images/alexdocas2.jpg";
import kmmm3 from "../../assets/images/church/IMG_8958.jpg";

// Images
import churchImg from "../../assets/images/ps-e-n-3.jpg";
import outreachImg from "../../assets/images/outreach.jpg";
import newHereImg from "../../assets/images/newHere.jpg";
import phonexxx from "../../assets/images/phone-app.png";

//video
import holyVideo from "../../assets/video/holy.mp4";
import mountainVideo from "../../assets/video/mountains.mp4";

import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { homeContent } from "../../constants/data";

function Home() {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });

  return (
    <>
      <section id="home">
        <div className="home_showcase">
          {/* <div className="secondaryGradient" />
          <div className="yellowGradient" /> */}
          <video autoPlay muted playsInline loop id="video" type="video/mp4">
            <source src={holyVideo} />
            {/* <source src={mountainVideo} /> */}
          </video>
          <div className="container">
            <div className="showcase_content">
              <div className="top">
                <p data-aos="zoom-in" data-aos-delay="200">
                  {homeContent.heroText1}
                </p>
                <p data-aos="zoom-in" data-aos-delay="400">
                  {homeContent.heroText2}
                </p>
              </div>
              <div className="links" data-aos="zoom-in" data-aos-delay="600">
                <Link className="link">
                  Join Us This Sunday
                  <span className="arrow_still"></span>
                </Link>

                <Link to="/weekly_programmes" className="link linkAnima">
                  Weekly Programme
                  <span className="arrow"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* ======= WELCOME SECTION ======= */}
        <div className="home_welcome">
          <div className="left">
            <p
              className="title"
              data-aos="fade-right"
              data-aos-anchor-placement="top-bottom"
            >
              OUR MISSION
            </p>

            {/* <p>Highway Of Holiness Church</p> */}
            <p
              data-aos="fade-right"
              data-aos-delay="100"
              // data-aos-offset="200"
              data-aos-anchor-placement="center-bottom"
              className="home_text"
            >
              {homeContent.missionText}
            </p>
          </div>
          <div className="right">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="400"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={churchImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">Services & Time</p>
                <p>Find our service times and direction to our location.</p>
                <Link to="/Weekly_programmes" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>

            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={outreachImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">Outreach</p>
                {/* <p>Find our services time and direction to our location</p> */}
                <Link to="/outreach" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>

            <div
              className="card"
              data-aos-delay="900"
              data-aos="fade-left"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={newHereImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">New Here?</p>
                {/* <p>Find our services time and direction to our location</p> */}
                <Link to="/im_new" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div
              className="shape"
              data-aos="slide-left"
              data-aos-anchor-placement="center-bottom"
            ></div>
          </div>
        </div>

        {/* PASTOR ALEX */}

        <div id="psalex">
          <div className="ps-alex-wrapper">
            <div className="ps-alex-show">
              <div className="ps-alex-case">
                <div className="ps-left">
                  <div
                    className="psl-pic"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="600"
                  >
                    <img src={kmmm3} alt="" style={{ objectFit: "contain" }} />
                  </div>
                </div>

                <div
                  className="ps-right"
                  data-aos="fade-left"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-delay="600"
                >
                  <div className="psr1">Our Believe</div>

                  <div className="psr2">
                    <p>{homeContent.ourBelieve}</p>
                    <p>
                      <h3>The Apostle’s Creed</h3>
                      {homeContent.apostleCreed}
                    </p>
                  </div>

                  <div className="psr3">
                    <Link to="/about" className="links linkAnima">
                      Read More <span className="arrow"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PASTOR ALEX end */}

        {/* ======= APP SECTION ======= */}
        <div className="home_app">
          <div className="container app_wrapper">
            <div className="left">
              <img src={phonexxx} alt="" />
            </div>

            <div className="right">
              <h1 className="text_center">GET SOCIAL WITH US</h1>
              <div className="bottom">
                <a
                  href="https://web.facebook.com/HighwayofHolinessUK"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>

                <a href="/" target="_blank" rel="noopener noreferrer">
                  <FiInstagram />
                </a>

                <a href="/" target="_blank" rel="noopener noreferrer">
                  <AiFillTwitterCircle />
                </a>

                <a
                  href="https://www.youtube.com/@ghanacalvarymethodistunite9662"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillYoutube />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* ======= MESSAGES SECTION ======= */}
        <div className="home_message">
          <div className="message_wrapper container text_center">
            <div className="top">
              <h2>PAST Messages</h2>
              <p>
                Checkout our past sermons and services held by Ghana Calvary
                Methodist United Church
              </p>
            </div>

            <div className="bottom">
              <div className="up">
                <div className="box">
                  <div className="hover_overlay">
                    <iframe
                      id="utube_video"
                      width="560"
                      height="270"
                      src="https://www.youtube.com/embed/cJcmkCEwMWs"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/cJcmkCEwMWs"
                  >
                    VIEW
                  </a>
                </div>

                <div className="box">
                  <div className="hover_overlay">
                    <iframe
                      id="utube_video"
                      width="560"
                      height="270"
                      src="https://www.youtube.com/embed/5raDLj7wLZs"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>

                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/5raDLj7wLZs"
                  >
                    VIEW
                  </a>
                </div>
                <div className="box">
                  <div className="hover_overlay">
                    <iframe
                      id="utube_video"
                      width="560"
                      height="270"
                      src="https://www.youtube.com/embed/hAs2cKT3-RU"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/hAs2cKT3-RU"
                  >
                    VIEW
                  </a>
                </div>
              </div>

              <div className="down">
                <div className="box">
                  <div className="hover_overlay">
                    <iframe
                      id="utube_video"
                      width="560"
                      height="270"
                      src="https://www.youtube.com/embed/c4e6N3fwLI0"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/c4e6N3fwLI0"
                  >
                    VIEW
                  </a>
                </div>
                <div className="box">
                  <div className="hover_overlay">
                    <iframe
                      id="utube_video"
                      width="560"
                      height="270"
                      src="https://www.youtube.com/embed/966P-PmeZoQ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href=" https://youtu.be/966P-PmeZoQ"
                  >
                    VIEW
                  </a>
                </div>

                <div className="box">
                  <div className="hover_overlay">
                    <iframe
                      id="utube_video"
                      width="560"
                      height="270"
                      src="https://www.youtube.com/embed/3MLf4-PCXsg"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <a
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/3MLf4-PCXsg"
                  >
                    VIEW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Slides />
      </section>
      <ScrollToTop />
    </>
  );
}

export default Home;
