import React from "react";
import "./Groups.css";
import { Link } from "react-router-dom";
// import AOS from "aos";
// import { ImSearch } from 'react-icons/im';
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";

const Groups = () => {
  // const [selects, setSelects] = useState();

  // const [isActive, setIsActive] = useState(false)

  // const [selected, setSelected] = useState('');

  return (
    <div id="super">
      <div className="group-page">
        <div className="gropup-wrapper">
          <div className="group-showcase">
            <div className="group-case">
              {/* showcase text */}
              <div className="group-text">
                <h1 className="focus-in-contract-bck">FELLOWSHIP GROUPS</h1>

                {/* <h2 className="focus-in-contract-bck">
                TIMES ARE IN MOUNTAIN TIMEZONE
              </h2> */}
              </div>
              {/* showcase text */}
            </div>
          </div>

          {/* shape and map */}

          <div className="map-shape-wrapper">
            <div className="map-shape">
              <div className="mp-sp">
                <div
                  className="mp slide-in-right"
                  // data-aos="fade-left"
                  // data-aos-delay="400"
                >
                  <GoogleMap />
                </div>

                <div className="sp">
                  <div
                    className="map-shape silde-in-right"
                    // data-aos="fade-left"
                    // data-aos-delay="500"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* shape and map end */}

          {/* search bar */}

          {/* <div className='sh-wrapper'>
        <div className='sh-case'>

          <div className='sh'>
            <div className='sh-1'>
              <input type='text' className='sh-tt' placeholder='SEARCH'></input>
            </div>

            <div className='sh-2'>
              <Link><ImSearch /></Link>
            </div>
          </div>
          
        </div>
      </div> */}

          {/* search bar end */}

          {/* individual church groups */}

          <div className="church-group-wrapper">
            <div className="church-group-case">
              {/* groups txt  */}

              <div className="fel-grp">
                <div className="tt-3">Fellowship Groups</div>

                <div className="tt-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est,
                  suscipit. Iure facilis doloremque odio impedit numquam facere
                  perferendis. Expedita, tempore!
                </div>
              </div>

              {/* groups txt end */}

              {/* group flex */}
              <div className="church-group">
                <div className="ch-gr">
                  <div className="ch-gr-1">GROUP 1</div>
                  <div className="ch-gr-1">LEADER - Name</div>
                  {/* <div className="ch-gr-2">
                  Wednesday: 7.30 pm - 8.30 pm
                  <br></br>
                  WORSHIP SERVICE
                </div>
                <div className="ch-gr-3 hover_overlay">
                  Unit 8 Fountayne House <br></br>
                  2-8 Fountayne Road <br></br>
                  London 4QL
                </div> */}
                  <div className="ch-gr-4">
                    <div className="bib">
                      <div className="bib-1">
                        {/* 3. PEACE GROUP <br></br> */}
                        <span className="hoo">(Philippians 4: 7)</span>
                      </div>
                      <div className="bib-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Minus officiis, velit sint illum vero culpa accusantium
                        saepe dolorum earum magnam!
                        {/* <span className='hoo'>
                    (1 Corinthians 13: 4 - 7)
                  </span> */}
                      </div>
                    </div>
                    {/* <Link className="linkAnima">
                    MORE INFO <span className="arrow"></span>{" "}
                  </Link> */}
                  </div>
                  <div className="ch-gr-5"></div>
                </div>

                <div className="ch-gr">
                  <div className="ch-gr-1">GROUP 2</div>
                  <div className="ch-gr-1">LEADER - Name</div>
                  {/* <div className="ch-gr-2">
                  Wednesday at 6: 30 PM <br></br>
                  Content: WeekendMessage
                </div>
                <div className="ch-gr-3 hover_overlay">
                  Unit 8 Fountayne House <br></br>
                  2-8 Fountayne Road <br></br>
                  London 4QL
                </div> */}
                  <div className="ch-gr-4">
                    {/* <Link className="linkAnima">
                      MORE INFO <span className="arrow"></span>{" "}
                    </Link> */}
                    <div className="bib">
                      <div className="bib-1">
                        {/* 5. POWER GROUP <br></br> */}
                        <span className="hoo">(Luke 24 : 49)</span>
                      </div>
                      <div className="bib-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Provident magni vel quam libero molestiae aperiam
                        tenetur delectus enim officiis dolore? <br></br>
                        {/* <span className='hoo'>
                    (1 Corinthians 13: 4 - 7)
                  </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="ch-gr-5"></div>
                </div>

                <div className="ch-gr">
                  <div className="ch-gr-1">GROUP 3</div>
                  <div className="ch-gr-1">LEADER - Name</div>
                  {/* <div className="ch-gr-2">
                  Wednesday at 6: 30 PM <br></br>
                  Content: WeekendMessage
                </div>
                <div className="ch-gr-3 hover_overlay">
                  Unit 8 Fountayne House <br></br>
                  2-8 Fountayne Road <br></br>
                  London 4QL
                </div> */}
                  <div className="ch-gr-4">
                    <div className="bib">
                      <div className="bib-1">
                        {/* 2. FAITH GROUP <br></br> */}
                        <span className="hoo">(Hebrews 11 : 1)</span>
                      </div>
                      <div className="bib-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Nihil magni libero, maiores asperiores animi et delectus
                        autem qui ex quasi. <br></br>
                        {/* <span className='hoo'>
                    (1 Corinthians 13: 4 - 7)
                  </span> */}
                      </div>
                    </div>
                    {/* <Link className="linkAnima">
                      MORE INFO <span className="arrow"></span>{" "}
                    </Link> */}
                  </div>
                  <div className="ch-gr-5"></div>
                </div>
              </div>
              {/* group flex end */}

              {/* group flex */}
              <div className="church-group">
                <div className="ch-gr">
                  <div className="ch-gr-1">GROUP 4</div>
                  <div className="ch-gr-1">LEADER - Name</div>
                  {/* <div className="ch-gr-2">
                  Wednesday at 6: 30 PM <br></br>
                  Content: WeekendMessage
                </div>
                <div className="ch-gr-3 hover_overlay">
                  Unit 8 Fountayne House <br></br>
                  2-8 Fountayne Road <br></br>
                  London 4QL
                </div> */}
                  <div className="ch-gr-4">
                    <div className="bib">
                      <div className="bib-1">
                        {/* 3. PEACE GROUP <br></br> */}
                        <span className="hoo">(Psalm 32:11)</span>
                      </div>
                      <div className="bib-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quod commodi magnam, velit tempora soluta reiciendis
                        repellendus accusantium tenetur pariatur beatae!
                        {/* <span className='hoo'>
                    (1 Corinthians 13: 4 - 7)
                  </span> */}
                      </div>
                    </div>
                    {/* <Link className="linkAnima">
                      MORE INFO <span className="arrow"></span>{" "}
                    </Link> */}
                  </div>
                  <div className="ch-gr-5"></div>
                </div>

                <div className="ch-gr">
                  <div className="ch-gr-1">GROUP 5</div>
                  <div className="ch-gr-1">LEADER - Name</div>
                  {/* <div className="ch-gr-2">
                  Wednesday at 6: 30 PM <br></br>
                  Content: WeekendMessage
                </div>
                <div className="ch-gr-3 hover_overlay">
                  Unit 8 Fountayne House <br></br>
                  2-8 Fountayne Road <br></br>
                  London 4QL
                </div> */}
                  <div className="ch-gr-4">
                    {/* <Link className="linkAnima">
                      MORE INFO <span className="arrow"></span>{" "}
                    </Link> */}
                    <div className="bib">
                      <div className="bib-1">
                        {/* 4. HOPE GROUP <br></br> */}
                        <span className="hoo">(Romans 15 : 13)</span>
                      </div>
                      <div className="bib-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Accusantium incidunt voluptatem saepe. Aspernatur,
                        nesciunt. Possimus asperiores molestiae voluptatem a
                        quas? <br></br>
                        {/* <span className='hoo'>
                    (1 Corinthians 13: 4 - 7)
                  </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="ch-gr-5"></div>
                </div>

                <div className="ch-gr">
                  <div className="ch-gr-1">GROUP 6</div>
                  <div className="ch-gr-1">LEADER - Name</div>
                  {/* <div className="ch-gr-2">
                  Wednesday at 6: 30 PM <br></br>
                  Content: WeekendMessage
                </div>
                <div className="ch-gr-3 hover_overlay">
                  Unit 8 Fountayne House <br></br>
                  2-8 Fountayne Road <br></br>
                  London 4QL
                </div> */}
                  <div className="ch-gr-4">
                    {/* <Link className="linkAnima">
                      MORE INFO <span className="arrow"></span>{" "}
                    </Link> */}
                    <div className="bib">
                      <div className="bib-1">
                        {/* 1. LOVE GROUP <br></br> */}
                        <span className="hoo">(1 Corinthians 13 : 4 - 7)</span>
                      </div>
                      <div className="bib-2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Pariatur nobis iste iusto excepturi enim sed at
                        assumenda repellendus eveniet nihil! Quasi saepe eius ad
                        ab nostrum, tempora aliquam eos fugit consequuntur nobis
                        consequatur nesciunt voluptatum vitae! Labore
                        necessitatibus nobis illo! <br></br>
                      </div>
                    </div>
                  </div>
                  <div className="ch-gr-5"></div>
                </div>
              </div>
              {/* group flex end */}

              {/* group flex */}

              {/* <div className="church-group">
              <div className="ch-gr">
                <div className="ch-gr-1">MEN'S GROUP</div>
                <div className="ch-gr-1">LEADER - SISTER JULIE</div>
                
                <div className="ch-gr-4">
                  <Link className="linkAnima">
                    MORE INFO <span className="arrow"></span>{" "}
                  </Link>
                </div>
                <div className="ch-gr-5"></div>
              </div>

              <div className="ch-gr">
                <div className="ch-gr-1">WOMEN'S GROUP</div>
                <div className="ch-gr-1">LEADER - SISTER MARCIA</div>
               
                <div className="ch-gr-4">
                  <Link className="linkAnima">
                    MORE INFO <span className="arrow"></span>{" "}
                  </Link>
                </div>
                <div className="ch-gr-5"></div>
              </div>

              <div className="ch-gr">
                <div className="ch-gr-1">WEEKLY PROGRAMMES</div>
                <div className="ch-gr-1">LEADER - SISTER MABEL</div>
               
                <div className="ch-gr-4">
                  <Link className="linkAnima">
                    MORE INFO <span className="arrow"></span>{" "}
                  </Link>
                </div>
                <div className="ch-gr-5"></div>
              </div>
            </div> */}

              {/* group flex end */}

              {/* <div className="tt-2"> 
                The church congregation is sectioned  into 5 fellowship groups that our lead by …
            </div> */}
            </div>
          </div>

          {/* individual church groups end */}
        </div>
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Groups;
