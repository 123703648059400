import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "./Im_new.css";
import newAboutImage from "../../assets/images/church/IMG_8958.jpg";
import singersImage from "../../assets/images/church/IMG_8821.jpg";
import Faq from "../../components/faq/Faq";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { imNewContent } from "../../constants/data";

// import mapImage from "../../assets/images/Figmap.png"

const Im_new = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        once: false,
        mirror: true,
      },
      []
    );
  });
  return (
    <div id="super">
      <section id="new_showcase">
        <div className="showcase_wrapper">
          <h1>I'M NEW</h1>
        </div>
      </section>

      {/* ======= SECTION : EXPECT ======= */}
      <section id="new_expect">
        <div className="left">
          <img src={newAboutImage} alt="slideImage" data-aos="fade-right" />
          <div
            className="shape_2"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-anchor-placement="center-bottom"
          ></div>
        </div>
        <div className="right">
          <h2
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-anchor-placement="center-bottom"
          >
            About GCMUC
          </h2>
          <p
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-anchor-placement="center-bottom"
          >
            {imNewContent.about}
          </p>
          <div
            className="new_expect_links"
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-anchor-placement="center-bottom"
          >
            <Link to="/about" className="linkAnima">
              LEARN MORE <span className="arrow"></span>{" "}
            </Link>
            <Link to="/prayer" className="linkAnima">
              PRAYER REQUEST<span className="arrow"></span>{" "}
            </Link>
          </div>
        </div>
      </section>

      {/* ======= SECTION : NEW_ABOUT =======
       */}
      <section id="new_about">
        <div className="new_about_wrapper">
          <div className="left">
            <div>
              <h2
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-anchor-placement="center-bottom"
              >
                What to expect
              </h2>
              <p
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-anchor-placement="center-bottom"
              >
                {imNewContent.whatToExpect}
              </p>
              <div
                className="new_expect_links"
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-anchor-placement="center-bottom"
              >
                <Link to="/about" className="linkAnima">
                  OUR BELIEFS <span className="arrow"></span>{" "}
                </Link>
                <Link to="/prayer" className="linkAnima">
                  PRAYER REQUEST<span className="arrow"></span>{" "}
                </Link>
              </div>
            </div>
          </div>

          <div className="right">
            <img src={singersImage} alt="slideImage" data-aos="fade-left" />
            <div
              className="shape_2"
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-anchor-placement="center-bottom"
            ></div>
          </div>
        </div>
      </section>
      <Faq />
      <section id="contact_form">
        <div className="form_wrapper container">
          <form action="/" method="POST" autoComplete="off">
            <ul>
              <li>
                <label for="first_name">
                  Name <span className="start_sign">*</span>
                </label>
                <div className="name_input">
                  <span>
                    <input
                      name="first_name"
                      id="first_name"
                      type="text"
                      required
                    />
                    <label for="first_name">First</label>
                  </span>
                  <span>
                    <input name="last_name" id="fiel_4" type="text" required />
                    <label for="last_name">Last</label>
                  </span>
                </div>
              </li>

              <li>
                <div className="email_input">
                  <span>
                    <label for="email">
                      Email <span className="start_sign">*</span>
                    </label>
                    <div>
                      <input name="email" id="email" type="email" required />
                    </div>
                  </span>
                  <span>
                    <label for="tel">Mobile number</label>
                    <div>
                      <input name="tel" id="tel" type="number" />
                    </div>
                  </span>
                </div>
              </li>
              <li>
                <label for="comments">Comments and Questions</label>
                <div>
                  <textarea
                    name="comments"
                    id="comments"
                    rows="10"
                    required
                    placeholder
                  ></textarea>
                </div>
              </li>
              <div className="am_new_btn">
                <li>
                  <input
                    className="btn new_submit"
                    type="submit"
                    value="Submit"
                  />
                </li>
              </div>
            </ul>
          </form>
        </div>
      </section>

      <section id="map">
        {/* <div className="ask text_center">
          <Link className="btn" to="/contact">
            DO YOU HAVE ANY
            <br /> QUESTIONS
          </Link>
        </div> */}
        {/* <div className="main_map">
          <img src={mapImage} alt="" />
        </div> */}
        <GoogleMap />
      </section>
      <ScrollToTop />
    </div>
  );
};

export default Im_new;
