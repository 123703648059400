import React, { useState } from "react";
import { CgSearchLoading } from "react-icons/cg";
import "./events.css";
import EventResults from "../../components/eventResults";
import { events } from "./../../constants/data";

const Events = () => {
  const [query, setQuery] = useState("");
  const keys = ["title", "month", "year", "dayText", "day"];
  const search = (data) => {
    return data.filter((event) =>
      keys.some((key) => event[key].toLowerCase().includes(query))
    );
  };
  return (
    <>
      <section className="e-header">
        <div className="container">
          <div className="eventHeader">
            <h1>events</h1>
          </div>
        </div>
      </section>
      <section className="event">
        <div className="container">
          <div className="topWrapper">
            <div className="tw-holder">
              <div className="topHeader">
                <h1>event list</h1>
                <p>use the filter below to find an event</p>
              </div>

              <div className="search">
                <input
                  type="text"
                  onChange={(e) => setQuery(e.target.value.toLowerCase())}
                />
                <span>Search</span>
                <CgSearchLoading className="search_icon" />
              </div>
            </div>
          </div>

          <EventResults data={search(events)} />
        </div>
      </section>
    </>
  );
};

export default Events;
