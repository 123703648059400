import React from "react";
import { Link } from "react-router-dom";
import "./results.css";

const EventResults = ({ data }) => {
  return (
    <>
      {data.map((event) => (
        <div className="eventListItem" key={event.id}>
          <div className="top">
            <div className="day">
              <h1>{event.dayText}</h1>
            </div>
            <div className="date">
              <small>
                {event.month} {event.day}, {event.year}
              </small>
            </div>
          </div>
          <div className="bottom">
            <div className="left">
              <p>
                {event.day} <br />
                {event.month}
              </p>
            </div>
            <div className="right">
              <div className="eventName">
                <Link to="/" className="links hover_overlay">
                  {event.title}
                </Link>
              </div>
              <div className="eventDetails">
                <div className="col">
                  <span>Location:</span>
                  <Link to="/" className="links hover_overlay">
                    {event.location}
                  </Link>
                </div>
                <div className="col">
                  <span>time:</span>
                  <p>
                    {event.dayText}, {event.startTime} - {event.endTime}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default EventResults;
