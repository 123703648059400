import { React } from "react";
// import { Link } from "react-router-dom";
import "./Leadership.css";
// Images
// import l1 from "../../assets/images/pastor-alex.jpg";
import l1 from "../../assets/images/ps-e-n-3.jpg";
import l22 from "../../assets/images/pswomen.jpeg";
import l33 from "../../assets/images/psroll.jpeg";
import naruto from "../../assets/images/church/IMG_8953.jpg";
import minlogo from "../../assets/brand/logo.png";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
// import { MdMailOutline } from "react-icons/md";
// import { GrMail } from "react-icons/gr";
import { useEffect } from "react";
import AOS from "aos";
import EmailContactButton from "../../components/mailto/Mailto";

const Leadership = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        once: false,
        mirror: true,
      },
      []
    );
  });

  return (
    <div id="super">
      <div className="leadership-page">
        <div className="leadership-wrapper">
          {/* shwowcse */}
          <div className="leadership-showcase">
            <div className="leadership-show">
              <div className="leadership-text">
                <h1 className="focus-in-contract-bck">LEADERSHIP</h1>
              </div>
            </div>
          </div>
          {/* shwowcse end */}

          <div className="leadd-info-case">
            <div className="leadd-info-show container">
              <div className="leadd">
                <div className="tt-3">Leadership – Pastor </div>

                <div className="tt-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nesciunt est quos saepe magni rerum delectus, illum tenetur
                  amet fuga nihil!
                </div>
                <div className="tt-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse,
                  facere.
                </div>
              </div>
            </div>
          </div>

          {/* leaders */}

          <div id="leade-wrapper">
            <div className="leade-case">
              <div className="leade-show">
                <div className="lead-flex-case">
                  <div className="lead-flex-show">
                    {/* fleaders 3 flex  */}

                    <div className="lead-flex">
                      <div className="lf1-pic">
                        <img src={l1} alt="" />
                      </div>
                      <div className="lf12">Pastor </div>
                      <div className="lf3">Head Pastor</div>

                      <EmailContactButton
                        emailAddress="info@gcmuc.ca"
                        subject="Message"
                      />
                    </div>

                    <div className="lead-flex">
                      <div className="lf1-pic">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Name</div>
                      <div className="lf3">Position</div>

                      <EmailContactButton
                        emailAddress="info@gcmuc.ca"
                        subject="Message"
                      />
                    </div>

                    <div className="lead-flex">
                      <div className="lf1-pic">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Name</div>
                      <div className="lf3">Position</div>

                      <EmailContactButton
                        emailAddress="info@gcmuc.ca"
                        subject="Message"
                      />
                    </div>

                    {/* fleaders 3 flex end */}
                  </div>

                  <div className="lead-flex-show">
                    <div className="lead-flex">
                      <div className="lf1-pic22">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Name</div>
                      <div className="lf3">Position</div>

                      <EmailContactButton
                        emailAddress="info@gcmuc.ca"
                        subject="Message"
                      />
                    </div>

                    <div className="lead-flex">
                      <div className="lf1-pic22">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Name</div>
                      <div className="lf3">Position</div>

                      <EmailContactButton
                        emailAddress="info@gcmuc.ca"
                        subject="Message"
                      />
                    </div>

                    <div className="lead-flex">
                      <div className="lf1-pic22">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Name</div>
                      <div className="lf3">Position</div>

                      <EmailContactButton
                        emailAddress="info@gcmuc.ca"
                        subject="Message"
                      />
                    </div>
                  </div>

                  {/* <div className="lead-flex-show">
                    

                    <div className="lead-flex">
                      <div className="lf1-pic22">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Minister Julie</div>
                      <div className="lf3">Leader For Faith Group</div>

                      <EmailContactButton
                        emailAddress="jolie@hohcentre.co.uk"
                        subject="Message"
                      />
                    </div>

                    <div className="lead-flex">
                      <div className="lf1-pic22">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Minister Mable</div>
                      <div className="lf3">Leader For Love Group</div>

                      <EmailContactButton
                        emailAddress="mabel@hohcentre.co.uk"
                        subject="Message"
                      />
                    </div>

                    <div className="lead-flex">
                      <div className="lf1-pic22">
                        <img src={minlogo} alt="" />
                      </div>
                      <div className="lf12">Minister Marcia</div>
                      <div className="lf3">Leader For Hope Group</div>

                      <EmailContactButton
                        emailAddress="marcia@hohcentre.co.uk"
                        subject="Message"
                      />
                    </div>

                    
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* leaders end */}

          {/* our leaders */}

          {/* <div className="values-wrapper">
            <div className="values">
              <div className="values-showcase">
                <div className="values-text-case    container">
                  <div className="values-tt1 focus-in-contract-bck">
                    OUR LEADERS
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* our leaders end */}

          {/* ALONE */}

          <div className="alone-wrapper">
            <div className="alone">
              <div className="alone-1" data-aos="fade-right">
                <div className="lone-1">WE DON'T DO THIS ALONE</div>
                <div className="lone-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                  quidem, repudiandae ducimus ad fuga architecto cupiditate
                  nesciunt tempora quae dolor recusandae in unde cum, commodi
                  odit illum aperiam. Error, voluptatem recusandae deserunt
                  asperiores eaque animi quibusdam veniam harum totam. Quibusdam
                  provident voluptatibus iusto veritatis distinctio consequatur
                  debitis deserunt delectus quidem?
                </div>

                <div className="lone-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Sapiente qui, eius ipsa odit officia, labore voluptates enim
                  itaque libero odio, minima nesciunt dolores excepturi? Vitae
                  consequatur provident maxime optio. Repellendus commodi
                  praesentium nesciunt libero asperiores officiis magni
                  voluptate ea, non nisi, totam temporibus blanditiis omnis!
                  Illum pariatur necessitatibus atque corporis.
                </div>
              </div>

              <div className="alone-2">
                <div
                  className="pic"
                  data-aos="fade-left"
                  data-aos-delay="200"
                  data-aos-anchor-placement="center-bottom"
                >
                  <img src={naruto} alt="" />
                </div>

                <div
                  className="shape-2"
                  data-aos="fade-left"
                  data-aos-delay="400"
                  data-aos-anchor-placement="center-bottom"
                ></div>
              </div>
            </div>
          </div>

          {/* ALONE END */}
        </div>
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Leadership;
