import React from "react";
import "./Kids.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import sem111 from "../../assets/images/x5.jpeg";
import kids from "../../assets/images/kidii.jpg";
import kidImage1 from "./../../assets/images/church/IMG_8843.jpg";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { AiFillYoutube } from "react-icons/ai";
import { events, imNewContent } from "./../../constants/data";
// import Kids_campus from '../../components/kids_campus/Kids_campus';

const Kids = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });

  return (
    <div id="super">
      <div id="min-group-wrapper">
        <div className="min-group">
          {/* showcase */}
          <div className="min-group-showcase">
            <div className="min-group-case">
              <div className="min-group-txt">
                <h1 className="focus-in-contract-bck">
                  Welcome to our church's Kids Ministry!
                </h1>
                {/* <h2 className="focus-in-contract-bck">BIRTH - FIFTH GRADE</h2> */}
              </div>
            </div>
          </div>
          {/* showcase end */}

          <section>
            <div className="kidsWrapper">
              <div
                className="col"
                data-aos="fade-right"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="600"
              >
                <div className="txtContent">
                  <h1>
                    every age. <br />
                    every stage.
                  </h1>
                  <p>
                    At GCMUC, we believe that children are a precious gift from
                    God and that nurturing their spiritual growth is of utmost
                    importance. Our Kids Ministry is dedicated to providing a
                    vibrant and engaging environment where children can
                    encounter God, learn biblical truths, and develop a lifelong
                    relationship with Jesus Christ.
                  </p>
                  <a className="btn" href="/">
                    NEW HERE?
                  </a>
                </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="600"
              >
                <div className="imgHolder">
                  <div className="imgOverlay">
                    <p>
                      We provide age-appropriate Sunday school sessions where
                      kids can study the Bible via engaging lessons, fun games,
                      and deep conversations. Children can explore their faith
                      and ask questions in a welcoming environment created by
                      our committed teachers.
                    </p>
                  </div>
                  <img src={kidImage1} alt="kids image" />
                </div>
                <div className="imgTitle">
                  <h2>sunday school</h2>
                </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
              >
                <div className="imgHolder">
                  <div className="imgOverlay">
                    <p>
                      Volunteer Opportunities: We urge parents and church
                      members to get active in our Kids Ministry because we
                      think that serving others is a crucial part of our
                      spiritual journey. There are numerous volunteer
                      possibilities, including leading small groups, helping
                      with events, teaching Sunday school, and giving
                      administrative support. To ensure that our volunteers are
                      properly prepared to serve, we offer training and tools.
                    </p>
                  </div>
                  <img src={kidImage1} alt="kids image" />
                </div>
                <div className="imgTitle">
                  <h2>serve in kids ministry</h2>
                </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="200"
              >
                <div className="imgHolder">
                  <div className="imgOverlay">
                    <p>
                      Parenting seminars: We recognize the value of parental
                      participation in a child's spiritual development. We
                      provide parenting seminars and workshops to give parents
                      useful tools and resources to help them deal with the
                      rewards and difficulties of raising faith-based children.
                    </p>
                    <p>
                      Family devotionals: We offer materials to assist families
                      in spending quality time in prayer and worship together.
                      {/* Our family devotionals provide activities, discussion
                      topics, and age-appropriate Bible studies to encourage
                      spiritual development among family members. */}
                    </p>
                    <p>
                      Parent-Child Dedication: Through our Parent-Child
                      Dedication ceremonies, we honour parents' dedication to
                      {/* bring up their kids in a godly manner. On this unique
                      occasion, parents can publicly declare their commitment to
                      their child's spiritual upbringing and receive the
                      congregation's prayers and support. */}
                    </p>
                  </div>
                  <img src={kidImage1} alt="kids image" />
                </div>
                <div className="imgTitle">
                  <h2>parent resources</h2>
                </div>
              </div>
            </div>
          </section>

          <section className="upcomingEvents">
            <div className="container">
              <h1>upcoming events</h1>
              <div className="eventsList">
                {events.map(({ title }, index) => {
                  return (
                    <a href="/events" className="eventItem" key={index}>
                      {title}
                    </a>
                  );
                })}
              </div>
              <Link to="/events" className="links linkAnima">
                FULL CALENDAR <span className="arrow"></span>
              </Link>
            </div>
          </section>

          <section className="whoWeAre">
            <div className="container">
              <div className="col">
                <div className="contentWrapper">
                  <div className="imgHolder">
                    <img src={kidImage1} alt="" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="contentWrapper">
                  <h1>
                    this is who <br /> we are
                  </h1>
                  <p>{imNewContent.about}</p>
                  <a href="/serve" className="btn">
                    SERVE
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="connect">
            <div className="container">
              <div className="col">
                <div className="contentWrapper">
                  <h1>you don't want to miss this</h1>
                  <a className="btn" href="/">
                    STAY IN THE KNOW
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="contentWrapper">
                  <div className="top">
                    <h4>you can connect with us through the following</h4>
                  </div>
                  <div className="bottom">
                    <div className="social">
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <AiFillTwitterCircle />
                      </a>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <FiInstagram />
                      </a>
                      <a
                        href="https://www.youtube.com/@ghanacalvarymethodistunite9662"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillYoutube />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ministry director  */}

          {/* <div className="min-dir-wrapper">
            <div className="min-dir-case">
              <div className="min-dir">
                <div className="mindir-a">
                  <img
                    src={sem111}
                    alt="sem111"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="600"
                  />
                </div>

                <div
                  className="mindir-b"
                  data-aos="fade-left"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-delay="600"
                >
                  <div className="dir-a">KIDS MINISTRY</div>
                  <div className="dir-b">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Eaque expedita aliquam suscipit reiciendis ullam, quam
                    quidem provident delectus voluptatem magni itaque nobis
                    numquam ex esse? Illo, repudiandae laudantium natus
                    consectetur suscipit doloribus asperiores error perspiciatis
                    qui, eum atque mollitia ipsum! Ad natus officia vel ea dicta
                    distinctio fugiat doloribus eveniet facere animi nesciunt
                    quia similique veniam aperiam ex perferendis labore nihil
                    reiciendis harum beatae dolor ullam neque, est expedita!
                    Accusantium?
                  </div>
                  <div className="dir-c">
                    <Link className="linkAnima" to="/contact">
                      Contact <span className="arrow"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* ministry director end */}

          {/* kids cards section */}

          {/* <div className="kids-cards-wrapper">
          <div className="kids-cards-case">
            

            <div className="kids-cards-show">
              

              <div
                className="kids-cards-1"
                data-aos="fade-left"
                data-aos-delay="600"
                data-aos-anchor-placement="top center"
              >
                <Link className="kdt">
                  <div className="kc-a">
                    <div className="kk-s-over">
                      <div className="kk-s"></div>
                      <div className="kk-txt">KLM KIDS</div>
                    </div>
                  </div>

                  <div className="kc-b">
                    <div className="kk-txt-1">
                      This Week's Lesson <br></br>
                      Kids - Christmas
                    </div>

                    <div className="kk-txt-2">CHRISTMAS - NOV. & EC.</div>
                  </div>
                </Link>
              </div>

             

              <div
                className="kids-cards-1"
                data-aos="fade-left"
                data-aos-delay="610"
                data-aos-anchor-placement="top center"
              >
                <Link className="kdt">
                  <div className="kc-a2">
                    <div className="kk-s-over">
                      <div className="kk-s"></div>
                      <div className="kk-txt">HOH KIDS</div>
                    </div>
                  </div>

                  <div className="kc-b">
                    <div className="kk-txt-1">
                      This Week's Lesson <br></br>
                      Kids - Christmas
                    </div>

                    <div className="kk-txt-2">CHRISTMAS - NOV. & EC.</div>
                  </div>
                </Link>
              </div>

              

              <div
                className="kids-cards-1"
                data-aos="fade-right"
                data-aos-delay="620"
                data-aos-anchor-placement="top center"
              >
                <Link className="kdt">
                  <div className="kc-a3">
                    <div className="kk-s-over">
                      <div className="kk-s"></div>
                      <div className="kk-txt">SUNDAY SCHOOL</div>
                    </div>
                  </div>

                  <div className="kc-b">
                    <div className="kk-txt-1">
                      This Week's Lesson <br></br>
                      Kids - Christmas
                    </div>

                    <div className="kk-txt-2">CHRISTMAS - NOV. & EC.</div>
                  </div>
                </Link>
              </div>

           
            </div>

           
          </div>
        </div> */}

          {/* kids cards section end */}

          {/* kids outreach */}

          {/* <div className="kid-out-wrapper">
            <div className="kids-out-show">
              <div className="kids-out">
                <div
                  className="kids-aa"
                  data-aos="fade-right"
                  data-aos-delay="600"
                  data-aos-anchor-placement="top center"
                >
                  <div className="ka-case">
                    <div className="ka-over">
                      <div className="ka-s"></div>
                      <div className="ka-txt">KIDS OUTREACH</div>
                    </div>
                  </div>
                </div>

                <div
                  className="kids-bb"
                  data-aos="fade-left"
                  data-aos-delay="600"
                  data-aos-anchor-placement="top center"
                >
                  <div className="kb-show">
                    <div className="kb-txt-1">Friends Around The World</div>
                    <div className="kb-txt-2">
                      Friends Around <br></br> The World
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* kids outreachend */}

          {/* plug uncomment to show */}

          {/* <Kids_campus /> */}

          {/* select your campus  */}

          {/* <div className='sel-cam-wrapper'>
        <div className='sel-cam-case'>
          <div className='sel-cam'>
            <div className='sc-a'>
              For More Information and Events
            </div>

            <div className='sc-b focus-in-contract-bck'>
              Select Your Campus
            </div>

            <div className='sc-c'></div>
          </div>
        </div>
      </div> */}

          {/* select your campus end */}

          {/* kids with special needs */}

          {/* <div className="special-wrapper">
            <div className="special-case">
              <div className="special">
                <div className="spec-a">
                 
                  KIDS MINISTRY
                </div>

                <div className="spec-b">
                  We want to care for and lead children through a variety of
                  means, from diapers to discipleship.We are shaping the future
                  generation of leaders, and we do not take this responsibility
                  lightly.We take great pleasure in giving our kids a real
                  encounter with God, which is accomplished via dependable,
                  biblically - based teaching, in a friendly setting, and with a
                  teaching staff that genuinely cares about kids and, quite
                  obviously, the greatest at what they do.
                </div>

                <div className="spec-b">
                  Train up a child in the way he should go; even when he is old
                  he will not depart from it.
                  <span className="hoo">Proverbs 22: 6</span>
                </div>

                <div className='spec-c'>
              <Link className="linkAnima">
                CONTACT CIRCLE OF FRIENDS <span className="arrow"></span>
              </Link>
            </div>
              </div>
            </div>
          </div> */}

          {/* kids with special needs end */}

          {/* ministrt events */}

          {/* <div className="min-eve-wrapper">
            <div className="min-eve-txt">Ministry Events</div>
          </div> */}

          {/* ministrt events end */}

          {/* seminar card  */}

          {/* <div className="seminar-wrapper">
            <div className="seminar-case">
              <div className="seminar-show">
                <div className="seminar">
                  <div
                    className="sem-a"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="600"
                  ></div>

                  <div
                    className="sem-b"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="600"
                  >
                    <img src={kids} alt="" />
                  </div>

                  <div
                    className="sem-c"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="600"
                  >
                    <div className="sem-c-1">
                    
                      <div className="sem-c-txt-3">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Earum cumque sunt reprehenderit in repellendus.
                        Saepe vero obcaecati voluptatibus magni et.
                      </div>
                      
                    </div>
                    <div className="sem-c-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* seminar card end */}

          {/* ministry director  */}

          {/* <div className="min-dir-wrapper">
            <div className="min-dir-case">
              <div className="min-dir">
                <div className="mindir-a">
                  <img
                    src={kids}
                    alt=""
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-delay="600"
                  />
                </div>

                <div
                  className="mindir-b"
                  data-aos="fade-left"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-delay="600"
                >
                  <div className="dir-a">REV ANITA </div>
                  <div className="dir-b">Kids Ministry Director</div>
                  <div className="dir-c">
                    <Link className="linkAnima" to="/contact">
                      Contact <span className="arrow"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* ministry director end */}
        </div>
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Kids;
