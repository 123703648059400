import React from "react";
import { useState } from "react";
import "./Header.css";
import logo from "../../assets/brand/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ImLocation } from "react-icons/im";
import { CgClose } from "react-icons/cg";

function Header() {
  // change bg
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  // change bg end

  const navigate = useNavigate();

  const [navOpen, setNavOpen] = useState(false);

  const [state, setState] = useState("home");

  const toggle = (screen) => {
    if (screen === "l&t") {
      navigate("/location");
    } else {
      navigate(-1);
    }
    setState(screen);
  };

  return (
    <div id="super">
      <div className={color ? "header-navbar header-bg" : "header-navbar"}>
        <div className="header-wrapper">
          <div className="header">
            <div className="header-1">
              <Link to="/">
                <img className="logo" src={logo} alt="logo" />
              </Link>
            </div>

            {/* location and times */}

            <div className="header-2">
              <div className="location-wrapper">
                <button
                  onClick={() => {
                    toggle("home");
                  }}
                  style={{ display: state === "l&t" ? "block" : "none" }}
                >
                  <span className="btn-name">
                    <CgClose className="iconn" />
                    <span className="loc-n1">CLOSE</span>
                  </span>
                </button>

                <button
                  className={color ? "txtColorBlue" : "txtColorWhite"}
                  onClick={() => {
                    toggle("l&t");
                  }}
                  style={{ display: state === "home" ? "block" : "none" }}
                >
                  <span className="btn-name">
                    <ImLocation className={color ? "iconBlue" : "iconWhite"} />
                    <span className="loc-n1"> UPCOMING EVENTS </span>
                  </span>
                </button>
              </div>

              {/* location and times end */}

              {/* menu 1 */}
              <div className="menu-icon" onClick={() => setNavOpen(!navOpen)}>
                <Link>
                  <div className={navOpen ? "hambox hamBoxOpen" : "hamBox"}>
                    <div className={navOpen ? "menu-1 spin" : "menu-1"} />

                    <div className={navOpen ? "menu-2 spin" : "menu-2"} />

                    <div className={navOpen ? "menu-3 spin" : "menu-3"} />
                  </div>
                </Link>
              </div>

              {/* menu 1 end */}
            </div>
          </div>

          {/* menu overlay */}
          <div
            className="menu-overlay"
            style={{
              // top: navOpen ? "0" : "-100%",
              right: navOpen ? "0" : "-100%",
              transitionDelay: navOpen ? "0s" : "0s",
            }}
          >
            {/* overlay menu */}

            <ul className="nav-links">
              <li className="nav-item neww">
                <NavLink to="/Im_new" onClick={() => setNavOpen(!navOpen)}>
                  <div className="shape"></div>
                  NEW?
                </NavLink>
                <div className="nav-item-wrapper"></div>
                {/* drop-dowm */}

                <div className="dropdown-menu">
                  {/* <Link
                    to="/contact_new_comer_leader"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    CONTACT NEW COMER LEADER
                  </Link> */}

                  {/* <Link
                    to="/prayer"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    PRAYER REQUEST
                  </Link> */}
                </div>

                {/* drop-dowm end */}
              </li>

              <li className="nav-item">
                {/* <NavLink to='/'> */}
                <div className="hel">
                  <div className="shape"></div>
                  <span className="men">EVENTS & TIME</span>
                </div>
                {/* </NavLink> */}
                <div className="nav-item-wrapper"></div>

                {/* drop-dowm */}

                <div className="dropdown-menu">
                  <Link
                    to="/location"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    UPCOMING EVENTS
                  </Link>
                  {/* <Link
                    to="/Youth_retreat"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    YOUTH RETREAT
                  </Link>
                  <Link
                    to="/Adult_retreat"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    ADULTS RETREAT
                  </Link>*/}
                  <Link
                    to="/Weekly_programmes"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    WEEKLY PROGRAMMES
                  </Link>
                </div>

                {/* drop-dowm end */}
              </li>

              <li className="nav-item zenn">
                {/* <NavLink> */}
                <div className="hel">
                  <div className="shape"></div>
                  <span className="men">ABOUT</span>
                </div>
                {/* </NavLink> */}

                <div className="nav-item-wrapper"></div>

                {/* drop-dowm */}

                <div className="dropdown-menu">
                  <Link
                    to="/About"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    ABOUT GCMUC
                  </Link>
                  <Link
                    to="/Leadership"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    LEADERSHIP
                  </Link>
                </div>

                {/* drop-dowm end */}
              </li>

              <li className="nav-item">
                {/* <NavLink to='/'> */}
                <div className="hel">
                  <div className="shape"></div>
                  <span className="men">GET INVOLVED</span>
                </div>
                {/* </NavLink> */}
                <div className="nav-item-wrapper"></div>

                {/* drop-dowm */}

                <div className="dropdown-menu">
                  <Link
                    to="/Outreach"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    OUTREACH & DONATION
                  </Link>
                  <Link
                    to="/serve"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    SERVE
                  </Link>
                  {/* <Link
                    to="/singing_band"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    SINGING BAND
                  </Link>
                  <Link
                    to="/ushering"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    SINGING BAND USHERING
                  </Link>
                  <Link
                    to="/boys_girls_brigade"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    BOYS & GIRLS BRIGADE
                  </Link>
                  <Link
                    to="/prayer"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    PRAYER WARRIOR
                  </Link>
                  <Link
                    to="/stewardship"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    CHAPEL STEWARDSHIP
                  </Link> */}
                </div>

                {/* drop-dowm end */}
              </li>

              <li className="nav-item">
                <NavLink to="/Ministries">
                  <div className="shape"></div>
                  <span className="men">MINISTRIES</span>
                </NavLink>
                <div className="nav-item-wrapper"></div>

                {/* drop-dowm */}

                <div className="dropdown-menu">
                  <Link
                    to="/Kids"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    KIDS
                  </Link>
                  {/* <Link
                  to="/Students"
                  onClick={() => setNavOpen(!navOpen)}
                  className="hover_overlay"
                >
                  STUDENTS
                </Link> */}
                  <Link
                    to="/choir"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    CHOIR
                  </Link>
                  <Link
                    to="/Young_adults"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    YOUTH
                  </Link>
                  <Link
                    to="/women_fellowship"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    WOMEN FELLOWSHIP
                  </Link>
                  <Link
                    to="/men_fellowship"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    MEN FELLOWSHIP
                  </Link>
                  <Link
                    to="/guild"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    GUILD
                  </Link>
                  <Link
                    to="/swma"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    SWMA
                  </Link>
                </div>

                {/* drop-dowm end */}
              </li>

              <li className="nav-item neww ">
                <NavLink to="/Messages" onClick={() => setNavOpen(!navOpen)}>
                  <div className="shape"></div>
                  MESSAGES
                </NavLink>
                <div className="nav-item-wrapper"></div>
              </li>

              <li className="nav-item">
                <NavLink to="/Ministries">
                  <div className="shape"></div>
                  <span className="men">GET IN TOUCH</span>
                </NavLink>
                <div className="nav-item-wrapper"></div>

                {/* drop-dowm */}

                <div className="dropdown-menu">
                  <Link
                    to="/contact"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    CONTACT US
                  </Link>

                  {/* <Link
                    to="/visit"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    VISIT OUR CHURCH
                  </Link> */}
                  {/* <Link
                    to="/join_us"
                    onClick={() => setNavOpen(!navOpen)}
                    className="hover_overlay"
                  >
                    JOIN OUR CHURCH
                  </Link> */}
                </div>

                {/* drop-dowm end */}
              </li>

              <li className="nav-item neww">
                <NavLink to="/donation" onClick={() => setNavOpen(!navOpen)}>
                  <div className="shape"></div>
                  DONATION
                </NavLink>
                <div className="nav-item-wrapper"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
