import React from "react";
import { Link } from "react-router-dom";
import "./contact.css";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";

function Contact() {
  return (
    <div>
      <section id="contact_showcase">
        <div className="showcase_wrapper text_center">
          <h2>CONTACT US</h2>
        </div>
      </section>

      <section id="contact_address">
        <div className="contact_wrapper">
          <div className="card">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum,
              mollitia?
            </p>
            {/* <p>Office Hours | M-TH, 10:00 AM – 4:00 PM</p> */}
            <p>Phone | +44208800000</p>

            <p>
              Email |{" "}
              <a href="mailto:info@gcmuc.ca" className="hover_overlay">
                Info@gcmuc.ca
              </a>
            </p>
          </div>
        </div>
      </section>

      <section id="contact_prayer">
        <div className="prayer_section container text_center">
          <h2>Lorem ipsum dolor sit amet consectetur.</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus
            vitae illum doloremque itaque blanditiis dicta veritatis deserunt,
            nostrum accusantium autem, facilis similique enim ducimus saepe esse
            sequi in aliquam facere amet optio temporibus harum ipsa ratione.
            Consequuntur tempore aperiam accusamus!
          </p>
          <Link to="/prayer" className="btn">
            SUBMIT A PRAYR REQUEST
          </Link>
        </div>
      </section>

      {/* ======= CONTACT_FORM SECTION ======= */}
      <section id="contact_form">
        <div className="form_wrapper container">
          <form
            action="https://88fcc1b06d43556476d88696654f2534"
            method="POST"
            autoComplete="off"
          >
            <ul>
              <li>
                <label for="first_name">
                  Name <span className="start_sign">*</span>
                </label>
                <div className="name_input">
                  <span>
                    <input
                      name="first_name"
                      id="first_name"
                      type="text"
                      required
                    />
                    <label for="first_name">First</label>
                  </span>
                  <span>
                    <input name="last_name" id="fiel_4" type="text" required />
                    <label for="last_name">Last</label>
                  </span>
                </div>
              </li>

              <li>
                <div className="email_input">
                  <span>
                    <label for="email">
                      Email <span className="start_sign">*</span>
                    </label>
                    <div>
                      <input name="email" id="email" type="email" required />
                    </div>
                  </span>
                  <span>
                    <label for="tel">Mobile number</label>
                    <div>
                      <input name="tel" id="tel" type="number" />
                    </div>
                  </span>
                </div>
              </li>

              <li>
                <label for="comments">Comments and Questions</label>
                <div>
                  <textarea
                    name="comments"
                    id="comments"
                    rows="10"
                    required
                    placeholder
                  ></textarea>
                </div>
              </li>

              <li>
                <input type="submit" value="Submit" />
              </li>
            </ul>
          </form>
        </div>
      </section>
      <ScrollToTop />
    </div>
  );
}

export default Contact;
