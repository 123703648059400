import image1 from "../../assets/images/church/IMG_8798.jpg";
import image2 from "../../assets/images/church/IMG_8873.jpg";
import image3 from "../../assets/images/church/IMG_8694.jpg";
import image4 from "../../assets/images/church/IMG_8777.jpg";
import image5 from "../../assets/images/church/IMG_8691.jpg";
import image6 from "../../assets/images/church/IMG_8796.jpg";
import image7 from "../../assets/images/church/IMG_8774.jpg";
import image8 from "../../assets/images/church/IMG_8670.jpg";
import image9 from "../../assets/images/church/IMG_8731.jpg";
import image10 from "../../assets/images/church/IMG_8927.jpg";

export const items = [
  {
    id: "1",
    cart: "Message",
    image: image1,
    title: "REFLECT, REPENT & RESTORE ",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/OhJZ4lMo2-g",
  },
  {
    id: "2",
    cart: "Word",
    image: image2,
    title: "Yearn for the food that Endures Pt 2",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/yH58QPJBKl8",
  },
  {
    id: "3",
    cart: "Word",
    image: image3,
    title: "GOD LOVES A CHEERFUL GIVER",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/wESKcrBvbqM",
  },
  {
    id: "4",
    cart: "Soul Winning Series:",
    image: image4,
    title: "HEAR THE VOICE OF THE LORD",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/3MLf4-PCXsg",
  },
  {
    id: "5",
    cart: "Soul Winning Series:",
    image: image5,
    title: "SECOND SUNDAY IN LENT - SHARING IN CHRIST'S SUFFERING",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/S6dbdx1AoVs",
  },
  {
    id: "6",
    cart: "Soul Winning Series:",
    image: image6,
    title: " Lent Day 12 - Rend your heart and not your garments",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/c4e6N3fwLI0",
  },
  {
    id: "7",
    cart: "Soul Winning Series:",
    image: image7,
    title: "GOD LOVES AND BLESSES A CHEERFUL GIVER",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/hAs2cKT3-RU",
  },
  {
    id: "8",
    cart: "Soul Winning Series:",
    image: image8,
    title: "RESTORATION HOUR HOPE IN SUFFERING",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/5raDLj7wLZs",
  },
  {
    id: "9",
    cart: "Soul Winning Series:",
    image: image9,
    title: "RESTORATION HOUR",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/cJcmkCEwMWs",
  },
  {
    id: "10",
    cart: "Soul Winning Series:",
    image: image10,
    title: "Who will Roll away The Stone?",
    names: " Etc Nketiah",
    utube: "https://www.youtube.com/embed/vLCwhIemR3Y",
  },
];
