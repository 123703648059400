import React from "react";
// import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import "./outreact_detail.css";

function OutreachDetail() {
  return (
    <>
      <section id="outreach_house" className="outreach_section">
        <h2 className="outreach_head">LOCAL COMMUNITY MISSIONS</h2>
        <div className="wrapper container">
          <div id="higway_house" className="outreach_house_main">
            <h2>Highway House</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptates, laudantium. Nisi et rem, architecto suscipit non
              nostrum tempore modi obcaecati corrupti beatae enim a consequatur
              nihil iure perferendis nulla quo qui voluptatum omnis facilis!
              Expedita maiores placeat deleniti porro ipsum velit quasi nam
              consequuntur eius! Qui, veritatis repellendus, et eligendi ipsum
              excepturi rerum quod, illum nisi pariatur molestiae labore amet.
              <br />
              <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
              repudiandae deserunt distinctio repellendus cum doloribus quidem
              harum ea architecto, quasi eum, omnis delectus ducimus magnam nisi
              ut blanditiis modi obcaecati perspiciatis in praesentium
              consequatur, excepturi rerum. Porro ratione hic a fuga ex earum
              amet eveniet mollitia, voluptas consequatur tenetur rem totam
              delectus. Non nulla saepe vero quia pariatur, eum minima.
              <br /> <br />
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Provident aut libero eveniet culpa sint perspiciatis voluptatem
              quasi cumque maiores minus sed, voluptatibus illum iusto!
              Obcaecati in, omnis nobis sequi neque impedit illo cupiditate,
              possimus esse, aliquam dolorum. Repellat, at? Repellat laudantium,
              sit quasi fugiat nemo minus quia illum cum dolorum voluptas. Earum
              impedit sit labore voluptatibus dignissimos, eos explicabo neque
              numquam! Laudantium repellendus numquam veritatis vitae tempore
              adipisci distinctio, dolor deleniti voluptates totam
              exercitationem optio accusantium dicta neque sint et! <br />
              <br />
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Possimus, quo magni corporis iste deserunt laboriosam eos earum?
              Nesciunt necessitatibus ducimus optio et, repellendus, magnam
              dolor perspiciatis autem voluptatem quasi cum voluptatum
              distinctio maxime minus fuga, esse aliquid ratione voluptate!
              Quibusdam, reprehenderit tempore incidunt saepe ipsa rerum dolore
              maxime qui voluptate.
            </p>
          </div>
        </div>
      </section>
      <section id="youth_club" className="outreach_section">
        <div className="wrapper container">
          <h2>Youth Club</h2>
          <p>Lorem ipsum dolor sit amet consectetur.</p>
          <h2></h2>
        </div>
      </section>

      <section id="centryeducation" className="outreach_section">
        <div className="wrapper container">
          <h2>21st Century Education</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique,
            modi. Eligendi facere consequuntur soluta, omnis perferendis in
            velit eum facilis suscipit inventore fuga aliquid laudantium
            cupiditate sint tenetur modi quisquam! Earum officia ad cum,
            sapiente doloribus odit minima deleniti harum.
          </p>
          <ul>
            <li>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste qui
              sapiente vero quidem iusto odit ipsa nam reprehenderit, rerum
              eaque.
            </li>
            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
              numquam recusandae amet quos tempore vel. Porro pariatur tempora
              nemo. Repellendus?
            </li>
          </ul>
        </div>
      </section>
      <section id="outreach_detail_shift" className="outreach_section">
        <div className="wrapper container">
          <h2>SHIFT</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi
            porro dolorem labore! Debitis laborum facere placeat deleniti id,
            officia voluptatibus, ipsa libero dolore saepe ipsum dolorem porro,
            quaerat aliquid nam. Unde, veniam libero? Quia nihil quaerat
            asperiores saepe facere doloribus beatae nemo laudantium, sint
            repellat praesentium, veniam ex, voluptate recusandae soluta ut
            rerum nisi commodi quibusdam debitis explicabo quos amet aperiam
            veritatis! Commodi explicabo molestiae, nobis eaque repellat beatae
            ipsum.
            <br />
          </p>
          <p>Connect with us on our social platforms</p>
          <p>
            Twitter -{" "}
            <a
              className="outreach_detail_social"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @loremfa-stack-2x
            </a>
          </p>
          <p>
            IG -{" "}
            <a
              className="outreach_detail_social"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @loremfa-stack-2x
            </a>
          </p>
        </div>
      </section>

      <section id="outreach_detail_klm" className="outreach_section">
        <h2 className="outreach_head">Global Missions</h2>
        <div className="wrapper container">
          <h2>GMUC</h2>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet animi
          tempora quas blanditiis tenetur ipsum illum incidunt nemo quam nam?
          Ipsam ipsa perferendis molestiae numquam. Consequatur nesciunt cumque
          odio totam error? Similique, unde odio? Recusandae, est. Sit quaerat
          repellendus ea quisquam mollitia, temporibus dolores. Esse et,
          adipisci velit rem voluptatum optio distinctio, sequi molestiae
          provident deserunt praesentium incidunt! Hic dolor voluptatum
          distinctio ut error vel mollitia iusto perferendis minus ratione.
          <br /> <br />
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae
          illo eaque perferendis accusantium ducimus voluptatem cum nobis aut
          doloremque in?
        </div>
      </section>

      <section id="outreach_pakistan" className="outreach_section">
        <div className="wrapper container">
          <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. At quidem
            molestiae laudantium vel dolor id aliquid debitis odio vitae
            consectetur.
          </p>
        </div>
      </section>

      <section id="outreach_alliance" className="outreach_section">
        <div className="wrapper container">
          <h2>Lorem ipsum dolor sit.</h2>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit
            consequatur saepe ipsam reiciendis distinctio! Omnis ullam neque
            ratione laborum magni maiores deleniti iusto! Veniam dicta aperiam
            cumque officiis blanditiis tempore.
          </p>
        </div>
      </section>

      <section id="outreach_affiliated" className="outreach_section">
        <div className="wrapper container">
          <h2>Lorem ipsum dolor sit amet.</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis
            nulla ipsa doloremque iusto voluptatem, nostrum similique iste neque
            labore ea repellat amet fugit dolorem provident excepturi
            dignissimos doloribus veniam error id libero at blanditiis
            recusandae quis odit! Repudiandae, numquam soluta!
          </p>
        </div>
      </section>
      {/* <ScrollToTop /> */}
    </>
  );
}

export default OutreachDetail;
