import React from "react";
import "./guild.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import sem111 from "../../assets/images/x5.jpeg";
import kids from "../../assets/images/kidii.jpg";
import choirImage1 from "./../../assets/images/church/IMG_8917.jpeg";
import choirImage2 from "./../../assets/images/church/IMG_8923.jpeg";
import choirImage3 from "./../../assets/images/church/IMG_8915.jpeg";
import choirImage4 from "./../../assets/images/church/IMG_8925.jpeg";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { AiFillYoutube } from "react-icons/ai";
import { events, imNewContent } from "./../../constants/data";
// import Kids_campus from '../../components/kids_campus/Kids_campus';

const Guild = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });

  return (
    <div id="super">
      <div id="min-group-wrapper">
        <div className="min-group">
          {/* showcase */}
          <div className="min-group-showcase">
            <div className="min-group-case">
              <div className="min-group-txt">
                <h1 className="focus-in-contract-bck">GUILD</h1>
                {/* <h2 className="focus-in-contract-bck">BIRTH - FIFTH GRADE</h2> */}
              </div>
            </div>
          </div>
          {/* showcase end */}

          <section>
            <div className="kidsWrapper">
              <div
                className="col"
                data-aos="fade-right"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="600"
              >
                <div className="txtContent">
                  {/* <h1>
                    Lorem. <br />
                    dolor sit.
                  </h1> */}
                  <p>
                    The Guild Fellowship is a dynamic and welcoming organization
                    that serves as the center of our church's community by
                    promoting spiritual development, deep relationships, and
                    community involvement. We are a varied collection of people
                    from many areas of life who are dedicated to putting Jesus
                    Christ's teachings into practice.
                  </p>
                  <a className="btn" href="/">
                    NEW HERE?
                  </a>
                </div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="600"
              >
                <div className="imgHolder">
                  <div className="imgOverlay" />
                  <img src={choirImage2} alt="kids image" />
                </div>
                <div className="imgTitle">{/* <h2>some heading</h2> */}</div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
              >
                <div className="imgHolder">
                  <div className="imgOverlay" />
                  <img src={choirImage3} alt="kids image" />
                </div>
                <div className="imgTitle">{/* <h2>some heading</h2> */}</div>
              </div>
              <div
                className="col"
                data-aos="fade-left"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="200"
              >
                <div className="imgHolder">
                  <div className="imgOverlay" />
                  <img src={choirImage4} alt="kids image" />
                </div>
                <div className="imgTitle">{/* <h2>some heading</h2> */}</div>
              </div>
            </div>
          </section>

          <section className="upcomingEvents">
            <div className="container">
              <h1>upcoming events</h1>
              <div className="eventsList">
                {events.map(({ title }, index) => {
                  return (
                    <a href="/events" className="eventItem" key={index}>
                      {title}
                    </a>
                  );
                })}
              </div>
              <Link to="/events" className="links linkAnima">
                FULL CALENDAR <span className="arrow"></span>
              </Link>
            </div>
          </section>

          <section className="whoWeAre">
            <div className="container">
              <div className="col">
                <div className="contentWrapper">
                  <div className="imgHolder">
                    <img src={choirImage1} alt="" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="contentWrapper">
                  <h1>
                    this is who <br /> we are
                  </h1>
                  <p>{imNewContent.about}</p>
                  <a href="/serve" className="btn">
                    SERVE
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="connect">
            <div className="container">
              <div className="col">
                <div className="contentWrapper">
                  <h1>you don't want to miss this</h1>
                  <a className="btn" href="/">
                    STAY IN THE KNOW
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="contentWrapper">
                  <div className="top">
                    <h4>you can connect with us through the following</h4>
                  </div>
                  <div className="bottom">
                    <div className="social">
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <AiFillTwitterCircle />
                      </a>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <FiInstagram />
                      </a>
                      <a
                        href="https://www.youtube.com/@ghanacalvarymethodistunite9662"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillYoutube />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Guild;
