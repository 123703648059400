import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./West.css";
import Search from "../../components/searchLocate/Search";
import AboutSlideIn from "../../components/aboutSlideIn/AboutSlideIn";
import westImg from "../../assets/images/westVolunteer.jpg";
import westPastorImg from "../../assets/images/longmont_pastor.jpg";
import familyNightImg from "../../assets/images/family_night.jpg";
import openHouseImg from "../../assets/images/open_house.jpg";
import hikingImg from "../../assets/images/hiking.jpg";
import marriageImg from "../../assets/images/marriage.jpg";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";

const West = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });

  return (
    <div id="super">
      <section id="about_showcase">
        <h1> YOUTH CLUB</h1>
        <p className="date">SUNDAY: 9 & 11AM</p>
        {/* <h1 className="focus-in-contract-bck">DENVER</h1> */}
        {/* <p className="date focus-in-contract-bck">SUNDAY: 9 & 11AM</p> */}
        <Search />
      </section>
      <section>
        <div className="about_welcome">
          <div
            className="left"
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-anchor-placement="top-bottom"
          >
            <p>ABOUT US</p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum quia quasi impedit, possimus harum inventore atque
              debitis dignissimos nobis voluptatem.
            </p>
          </div>

          <div className="right">
            <AboutSlideIn />

            <div
              className="card"
              data-aos-delay="900"
              data-aos="fade-left"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={westImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">Lorem ipsum dolor sit amet.</p>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptates quia libero sequi porro distinctio aut expedita
                  labore atque facere quisquam.
                </p>
                <Link to="/serve" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div
              className="shape"
              data-aos="slide-left"
              data-aos-anchor-placement="center-bottom"
            ></div>
          </div>
        </div>
      </section>

      {/* ======= WEST_EVENTS SECTION =======     */}
      <section id="wester_ministries">
        <div className="about_welcome container">
          <h2>ministries</h2>
          <div className="right">
            <div className="card">
              <img src={familyNightImg} alt="" />
              <div className="text">
                <p className="hover_overlay">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Necessitatibus nam aspernatur consectetur consequatur eaque
                  sequi quis nulla? Nobis, quo beatae?
                </p>
                <Link to="/about" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={openHouseImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">
                  Lorem ipsum dolor sit amet.
                  <br />
                  01/22/2023
                </p>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam
                  a odio explicabo dicta minima vel esse possimus quos aliquam
                  nihil.
                </p>
                <Link to="/about" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={hikingImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">
                  Lorem ipsum dolor sit amet.
                  <br />
                  02/04/2023
                </p>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptatum ducimus eius ex doloremque quos ad deserunt placeat
                  temporibus est error.
                </p>
                <Link to="/about" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>{" "}
            <div className="card">
              <img src={marriageImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">
                  Lorem ipsum dolor sit amet.
                  <br />
                  02/10/2023
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Mollitia ut est quaerat fugiat eveniet ex vero officia
                  asperiores, perspiciatis odio.
                </p>
                <Link to="/about" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= west_reach section ======= */}
      <section id="west_reach">
        <div className="reach_wrapper">
          <div className="left">
            <img
              src={westPastorImg}
              alt=""
              data-aos="fade-right"
              data-aos-anchor-placement="center-bottom"
              data-aos-delay="600"
            />
          </div>
          <div
            className="right"
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-anchor-placement="top center"
          >
            <h2>Lorem, ipsum.</h2>
            <p>Campus Pastor</p>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum,
              quibusdam voluptate. Accusamus iste eligendi porro, deleniti sequi
              ullam soluta rerum quisquam similique velit vero dolores quasi id,
              eos debitis nam assumenda magni tempore illum nulla. Temporibus
              odit quae laborum doloremque, odio vitae, aliquid facilis
              excepturi numquam laboriosam cumque fugiat. Laudantium.
            </p>
            <Link className="linkAnima" to="/contact">
              Contact <span className="arrow"></span>
            </Link>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </div>
  );
};

export default West;
