import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "./WeeklyRetreat.css";
import { Link } from "react-router-dom";
// import Search from "../../components/searchLocate/Search";
// import SingleCard from "../../components/aboutSlideIn/SingleCard";
// import { FaFacebook } from "react-icons/fa";
// import { AiFillYoutube } from "react-icons/ai";

import newsImage from "../../assets/images/gpo1.jpeg";
import voluImg from "../../assets/images/gcmuc-001-bw.jpg";
import mediaImg from "../../assets/images/media.jpg";
import longPastorImg from "../../assets/images/pastorinagbaja.jpg";
import l1 from "../../assets/images/ps-e-n-2-2.jpg";
import studentsImg from "../../assets/images/church/IMG_8843.jpg";
import connectImg from "../../assets/images/church/IMG_8919.jpg";
import kidsImg from "../../assets/images/church/IMG_8774.jpg";
import womenImg from "../../assets/images/church/IMG_8818.jpg";
import menImg from "../../assets/images/church/IMG_8957.jpg";
import guildImg from "../../assets/images/church/IMG_8670.jpg";
import podcastImg from "../../assets/images/podcast.jpg";
import ScrollToTop from "../../components/ScrollToTop/ScrollTopTop";

const Longmont = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        // offet: 1000,
      },
      []
    );
  });

  return (
    <div id="super">
      <section id="weekly_programme_showcase">
        <div className="showcase_wrapper">
          <h1 className="focus-in-contract-bck">WEEKLY PROGRAMME</h1>
          {/* <p className="date focus-in-contract-bck">SUNDAY: 9 & 11 AM</p> */}
          {/* <Search /> */}
        </div>
      </section>

      <section id="longmont_about">
        <div className="about_wrapper container">
          <h1 className="text_center">WEEKLY PROGRAMMES</h1>
          <p className="text_center">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione,
            quod quo repellendus blanditiis cum, accusamus possimus quidem
            repudiandae quos suscipit sequi reiciendis illo modi rerum dolorum
            vitae maxime. Assumenda autem, nulla ex iste itaque et excepturi hic
            recusandae delectus facilis libero dolores id illo necessitatibus
            accusantium perferendis quisquam, cum minima? Minima blanditiis
            iusto at beatae illum enim in non, magnam a eaque ab? Accusamus
            voluptas debitis, excepturi est officiis exercitationem repudiandae
            aliquam perspiciatis ipsum id inventore beatae quaerat dolorem hic.
          </p>
        </div>
      </section>

      {/* <section id="longmont_reach">
        <div className="reach_wrapper">
          <div className="left">
            <img
              src={l1}
              alt=""
              data-aos="fade-right"
              data-aos-anchor-placement="center-bottom"
              data-aos-delay="600"
            />
          </div>
          <div
            className="right"
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-anchor-placement="top center"
          >
            <h2>Etc Nyarko</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore
              quidem officiis alias odio reiciendis cum in consectetur!
              Provident dolore ad harum amet nesciunt ipsa non odio deserunt
              voluptatum fugit voluptate, inventore quod temporibus officiis
              quibusdam animi suscipit laboriosam eaque repudiandae nisi atque
              asperiores delectus, aperiam aliquam. Nihil ullam iste illum?
            </p>
            <Link className="linkAnima" to="/contact">
              Contact <span className="arrow"></span>
            </Link>
          </div>
        </div>
      </section> */}

      <section id="longmont_ministries">
        <div className="about_welcome container">
          <h2>ministries</h2>
          <div className="right">
            <div className="card">
              <img src={studentsImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">KIDS</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet
                  numquam quos fuga unde, quis ipsum voluptatum, qui est vitae,
                  error officiis illum ut at animi obcaecati debitis!
                  Cupiditate, dolore? Distinctio.
                </p>
                <Link to="/kids" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={connectImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">CHOIR </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                  molestias atque facere. Beatae reiciendis aspernatur molestiae
                  alias quo, fugiat accusamus.
                </p>
                <Link to="/kids" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={kidsImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">YOUTH</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, possimus est ad delectus aperiam beatae?
                </p>
                <Link to="/young_adults" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={womenImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">WOMEN FELLOWSHIP</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, possimus est ad delectus aperiam beatae?
                </p>
                <Link to="/young_adults" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={menImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">MEN FELLOWSHIP</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, possimus est ad delectus aperiam beatae?
                </p>
                <Link to="/young_adults" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div className="card">
              <img src={guildImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">GUILD</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, possimus est ad delectus aperiam beatae?
                </p>
                <Link to="/young_adults" className="linkAnima">
                  Learn More <span className="arrow"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= SECTION : PADCAST ======= */}
      {/* <section id="longmont_podcast" style={{ marginTop: "150px" }}>
        <div className="left ">
          <img src={podcastImg} alt="slideImage" data-aos="fade-right" />
          <div
            className="shape_2"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-anchor-placement="top-center"
          ></div>
        </div>

        <div
          className="right text_center"
          data-aos="fade-left"
          data-aos-anchor-placement="center-bottom"
        >
          <h1
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-anchor-placement="center-bottom"
          >
            HoH Social Media Engaements
          </h1>
          <p
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-anchor-placement="center-bottom"
          >
            Are you struggling on the road of Faith? Listen to the stories from
            fellow travelers: the lessons learned and the abundant life on the
            horizon.
          </p>

          <div className="social_icons">
            <a
              className="hover_overlay text_center"
              href="https://web.facebook.com/HighwayofHolinessUK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>

            <a
              className="hover_overlay text_center"
              href="https://www.youtube.com/@highwayofholinesschristian7997"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillYoutube />
            </a>
          </div>

          <Link to="/About_HoH" className="linkAnima">
            OUR BELIEFS <span className="arrow"></span>{" "}
          </Link>
        </div>
      </section> */}

      <section>
        <div className="about_welcome">
          <div
            className="left"
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-anchor-placement="top-bottom"
          >
            <p>SERVE WITH US</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt
              consequuntur quaerat quibusdam blanditiis totam ratione alias, sit
              voluptates maxime ullam.
            </p>
          </div>

          <div className="right">
            {/* <SingleCard /> */}

            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={mediaImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">MEDIA</p>
                <p>
                  Be part of God's work by joining the media team @ Ghana
                  Calvary Methodist Church.
                </p>
                <Link to="/contact" className="linkAnima">
                  Serve with us <span className="arrow"></span>
                </Link>
              </div>
            </div>

            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={voluImg} alt="" />
              <div className="text">
                <p className="hover_overlay ">WELFARE</p>
                <p>
                  Be part of God's work by joining the welfare team @ Ghana
                  Calvary Methodist Church.
                </p>
                <Link to="/contact" className="linkAnima">
                  Serve with us <span className="arrow"></span>
                </Link>
              </div>
            </div>

            <div
              className="card"
              data-aos-delay="900"
              data-aos="fade-left"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={newsImage} alt="" />
              <div className="text">
                <p className="hover_overlay ">USHERING</p>
                <p>
                  Be part of God's work by joining the ushering team @ Ghana
                  Calvary Methodist Church.
                </p>
                <Link to="/contact" className="linkAnima">
                  Serve with us <span className="arrow"></span>
                </Link>
              </div>
            </div>
            <div
              className="shape"
              data-aos="slide-left"
              data-aos-anchor-placement="center-bottom"
            ></div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </div>
  );
};

export default Longmont;
